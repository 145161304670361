<template>
    <div>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <div class="d-block pt-3 mb-4">
                    <div class="row align-items-center">
                        <div class="col">
                            <h1 class="header-title">
                                Pengumuman
                                <small class="form-text text-muted">
                                    Menu Ini Digunakan Untuk Mengelola Pengumuman
                                </small>
                            </h1>
                        </div>
                        <div class="col-auto">
                            <router-link :to="{name:'pengumuman.tambah'}" class="btn btn-primary ml-2">
                                <div class="d-flex align-items-center">
                                    <i class="fe fe-plus mr-2"></i>Tambah Pengumuman
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <!-- CONTENT -->
                <div class="tab-content">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <div class="card-header">
                                <div class="input-group input-group-flush input-group-merge">
                                    <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari pengumuman...">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-search"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-sm table-wrap card-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <!-- Checkbox -->
                                                <div class="custom-control custom-checkbox table-checkbox">
                                                    <input type="checkbox" class="custom-control-input" name="ordersSelect" id="ordersSelectAll">
                                                    <label class="custom-control-label" for="ordersSelectAll">&nbsp;</label>
                                                </div>

                                            </th>
                                            <th>
                                                Pengumuman
                                            </th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="fetching">
                                        <tr>
                                            <td colspan="5">
                                                <div class="alert alert-primary" role="alert">
                                                    Sedang mengambil data..
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody class="list" v-if="!fetching">
                                        <tr v-for="(pengumuman, index) in dataPengumuman" :key="index">
                                            <td>
                                                <div class="custom-control custom-checkbox table-checkbox">
                                                    <input type="checkbox" class="custom-control-input" name="ordersSelect" id="orders1">
                                                    <label class="custom-control-label" for="orders1">&nbsp;</label>
                                                </div>
                                            </td>
                                            <td>
                                                <h4 class="mt-3 judul-pengumuman">{{ pengumuman.title }}</h4>
                                                <p>{{ pengumuman.summary }}</p>
                                                <hr>
                                                <div class="d-block mb-2"><small>created : {{ pengumuman.created_at }} | status : {{ pengumuman.status }}</small>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="d-flex">
                                                    <router-link :to="{ name: 'pengumuman.edit', params: { id: pengumuman.id } }" class="btn btn-white mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="Lihat pengumuman"><span class="fe fe-edit"></span></router-link>
                                                    <button @click.prevent="postDelete(pengumuman.id)" class="btn btn-white" data-toggle="tooltip" data-placement="top" title="" data-original-title="Hapus pengumuman"><span class="fe fe-trash-2"></span></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer border-top-0">
                                <div style="float:right">
                                    <!-- <ul class="pagination mb-0"></ul> -->
                                    <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    export default {
        data() {
            return {
                dataPengumuman: [],
                fetching: true,
                pagination: {
                    currentPage: 1,
                    totalPages: 0,
                    limit: 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: ''
            }
        },
        created() {
            this.getData();
        },
        components: {
            vPagination
        },
        methods: {
            getData() {
                this.fetching = true;
                this.$http.get(this.$apiconfig + "admin/blog/announcement", {
                        params: {
                            page: this.pagination.currentPage,
                            keywords: this.keywords,
                        }
                    })
                    .then((response) => {
                        this.dataPengumuman = response.data.data;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            postDelete(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.$apiconfig + 'admin/blog/delete',
                            method: 'post',
                            data: fd
                        }).then(() => {
                            this.$swal(
                                'Deleted!',
                                'Data deleted successfully.',
                                'success'
                            )
                            this.getData();
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
        },
    };
</script>